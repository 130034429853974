import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Scalaz Imports",
  "subtitle": "those imports you always need but can never remember.",
  "date": "2018-08-30T00:00:00.000Z",
  "layout": "note",
  "draft": false,
  "tags": ["scala", "scalaz"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`The Scalaz import Pattern:`}</h2>
    <p>{`Enrichment methods for typeclasses are located in:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`typeclass`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_`}</code></pre>{`
      `}</div>
    <p>{`Enrichment for standard libs are located in:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`std`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_`}</code></pre>{`
      `}</div>
    <p>{`Typeclasses for std library classes are usually located in:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`std`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_`}</code></pre>{`
      `}</div>
    <h2>{`Id`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` scalaz`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Id

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` string`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"adsf"`}</span></code></pre>{`
      `}</div>
    <h2>{`Equals`}</h2>
    <p>{`In order to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`===`}</code>{`, you need the equals syntax, and an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal[A]`}</code>{` instance, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` is the type you're comparing.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`equal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_ `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for ===`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Equal[String]`}</span>{`

`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span></code></pre>{`
      `}</div>
    <p>{`If you're comparing a generic type, such as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Option`}</code>{`, you'll need an instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal[Option[A]]`}</code>{`. The implicit Option equals build can make this easy if you have an instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Equal[A]`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`equal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_ `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for ===`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Equal[String]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`option`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for optionEqual[A]`}</span>{`

Option`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Option`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <h2>{`Disjunction`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`either`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_

`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.`}</span>{`right
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Error"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`left`}</code></pre>{`
      `}</div>
    <h2>{`Semigroup`}</h2>
    <p>{`for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`|+|`}</code>{`, you need the semigroup syntax, and a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Semigroup[A]`}</code>{`, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` is the type you're adding.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`semigroup`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for |+|`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Semigroup[String]`}</span>{`

`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span></code></pre>{`
      `}</div>
    <h2>{`Traverse`}</h2>
    <p>{`For `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.traverse`}</code>{`, you need the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`traverse`}</code>{` syntax, a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Traverse[F]`}</code>{`, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`F`}</code>{` is the collection type, and an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Applicative[G]`}</code>{`, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`G`}</code>{` is the type you're traversing into.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`traverse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for .traverse`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`list`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_         `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Traverse[List]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`option`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_       `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Applicative[Option]`}</span>{`

List`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`traverse `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  a `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`=>`}</span>{` Option`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <h2>{`Suml`}</h2>
    <p>{`For `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.suml`}</code>{`, you need the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`foldable`}</code>{` syntax, a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Foldable[F]`}</code>{`  where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`F`}</code>{` is the type of the collection you're squashing, and a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monoid[A]`}</code>{`, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` is the type inside your foldable.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scala"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-scala"
      }}><code parentName="pre" {...{
          "className": "language-scala"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`syntax`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`foldable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for .suml`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`list`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_         `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Foldable[List]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_       `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Monoid[String]`}</span>{`

List`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"bar"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"baz"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`suml   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// "foobarbaz"`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`scalaz`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`std`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`option`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`_       `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// for Monoid[Option]`}</span>{`

List`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Some`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"foo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` None`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Some`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"baz"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`suml `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Some("foobaz")`}</span></code></pre>{`
      `}</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      